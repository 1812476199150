<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            item-key="id"
            sort-by="id"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Users List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  ref="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="750px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on"
                      >New User</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.role"
                              :items="[
                                'Admin',
                                'Player'
                              ]"
                              label="Role"
                              prepend-icon="mdi-account"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-file-input
                              label="Photo"
                              accept="image/*"
                              filled
                              prepend-icon="mdi-camera"
                              required
                            ></v-file-input>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                          <v-autocomplete
                              placeholder="Select Club"
                              :items="clubList"
                              item-text="name"
                              item-value="id"
                              label="Club"
                              v-model="editedItem.club"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.alias"
                              label="Alias"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.phone_number"
                              label="Phone Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.cellphone_number"
                              label="Cellphone Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.mail"
                              label="Mail"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.pass"
                              label="Pass"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.adress"
                              label="Adress"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="save"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.role="{ item }">
              <v-chip color="green" dark v-if="item.role == 'Admin'">
                admin
              </v-chip>
              <v-chip color="blue" dark v-else-if="item.role == 'Player'">
                player
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-b-tooltip.hover
                title="Edit"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
                v-b-tooltip.hover
                title="Delete"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length + 1">
                <div class="row justify-center">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-3">
                        <p>
                          <b>Phone Number:</b><br />
                          {{ item.phone_number }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Cellphone Number:</b><br />
                          {{ item.cellphone_number }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Adress:</b><br />
                          {{ item.adress }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Mail:</b><br />
                          {{ item.mail }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri: "users",
    dialog: false,
    search: "",
    expanded: [],
    singleExpand: false,
    clubList: [],
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Role", value: "role", sortable: true },
      { text: "Name", value: "name", align: "start", sortable: false },
      { text: "Club", value: "club_name", align: "start", sortable: false },
      { text: "Actions", value: "actions", sortable: false }
    ],
    users: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      role: "",
      name: "",
      alias: "",
      phone_number: "",
      cellphone_number: "",
      adress: "",
      mail: "",
      pass: "",
      club: ""
    },
    defaultItem: {
      id: 0,
      role: "",
      name: "",
      alias: "",
      phone_number: "",
      cellphone_number: "",
      adress: "",
      mail: "",
      pass: "",
      club: ""
    }
  }),

  computed: {
    ...mapGetters([ "currentUser" ]),

    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if(this.currentUser.role != 'Admin') {
        window.location = './../';
      }

      try {
        var _self = this;
        genericService.getRecordList('/'+this.uri, function(records){
           _self.users = records
        });
      }catch(exception){
        //console.log(exception);
      }
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = this.users.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          this.users.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        this.users.push(this.editedItem);
        genericService.createRecord("/"+this.uri, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      }
      this.close();
    }
  },
  components: {},
  mounted() {
    var _self = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
    setTimeout(() => {
      this.$refs.search.focus();
    }, 500);

    genericService.getRecordList("/club/", function (records) {
      _self.clubList = records;
    });
  }
};
</script>
